.form {
  background-color: lightgray;
}

.form input {
  border-style: initial;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 10px;
  background-color: white;
}

.form label {
  margin: 10px;
  padding: 10px;
  font-size: medium;
}

.form select {
  margin: 10px;
  border-style: initial;
  border-radius: 5px;
  padding: 10px 10px;
}

/* Vertically center the modal */
/* Ensures that the modal is always on top */
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modal-dialog {
  z-index: 1060;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

/* Custom styling for modal (optional) */
.modal-content {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-header {
  background-color: #f8f9fa; /* light gray */
  border-bottom: 1px solid #e9ecef; /* border color */
}

.modal-footer {
  background-color: #f8f9fa; /* light gray */
  border-top: 1px solid #e9ecef; /* border color */
}

.modal-title {
  color: #333; /* dark text color */
}

.custom-close-btn {
  background-color: red; /* Red circle */
  border-width: 0px;
  border-radius: 50%; /* Makes it a circle */
  color: white; /* White cross */
  width: 25px; /* Define your circle size */
  height: 25px;
  line-height: 24px; /* Centers the cross vertically */
  text-align: center; /* Centers the cross horizontally */
  opacity: 1; /* Removes default opacity of close button */
  padding: 0; /* Removes default padding */
}

.custom-close-btn:hover {
  color: white; /* Keeps the cross white on hover */
  background-color: darkred; /* You can darken the red on hover */
}

.border-danger {
  border: 4px solid red;
}

.border-success {
  border: 4px solid green;
}/*# sourceMappingURL=Modal.css.map */