$layout-breakpoint-small: 575.98px;

$infocolor: rgb(27, 200, 230);

@media (max-width: $layout-breakpoint-small) {
    .clock-in-btn {
    font-size: 80%;
    }
    
    .clock-out-btn {
    font-size: 80%;
}
}

.table-wrapper {
    width: 100%;
}

table {
    background-color: #ffffff;
    color: #222;
    display: block;
    overflow: hidden;
    table-layout: fixed;
    border-collapse: collapse;
    box-shadow: 0px 10px 10px #ccc;
    border-radius: 10px;
    white-space: nowrap;
    width: 100em;
    max-width: 100%;
    margin: 5% 0%;
    overflow-x: auto;
}

thead tr {
    background-color: #ccc;
    color: #222;
    padding: 1% 1% 1% 0%;
}


table th, table td {
    padding: 0.5rem;
}

table .diff {
    padding: 0.5rem;
    font-weight: 800;
}

table .normal {
     padding: 0.5rem;
     cursor: pointer;
}


table .subtotal {
    border-top: 2px solid #ccc;
    margin-bottom: 10px;
}

table .subtotalText {
    color: #e10d05;
    font-weight: 800;
    margin: 0.5rem;
}

table .difference {
    border-top: #222 solid 1px;
}

table td {
    background-color: #eee; 
    overflow: hidden;
    text-overflow: ellipsis;
}

table tbody tr:hover {
    background-color: #eee;
}

.over-hours-limit {
  color: red;
}

.expand {
    width: 100%;
}

.timer {
    color: black
}

.actions {
    display: flex;
    justify-content: space-around;
    padding: 0.2rem;
}

.actions svg {
    cursor: pointer;
}

.delete-btn {
    color: #e10d05;
    font-size: x-large;
    padding: 0.2rem;
}

.edit-btn {
    color: $infocolor;
    font-size: x-large;
    padding: 0.2rem;
}

.cico-btn-group {
 width: 100%;
 height: 180px;
 font-size: xx-large;
 align-items: center;
 justify-content: center;
}

.cico-btn-group .clock-in-btn {
    border: 0px solid #ccc;
    border-radius: 10px 0px 0px 10px;
    padding-top: 0px;
    height: 180px;
    max-width: 300px;
    background-color: lightgreen;
    width: 50%;
      box-shadow: 0px 10px 10px #ccc;
}

.cico-btn-group .clock-out-btn {
    border: 0px solid #ccc;
    border-radius: 0px 10px 10px 0px;
    padding: 0px;
    height: 180px;
    max-width: 300px;
    background-color: #e10d05;
    color: #ddd;
    width: 50%;
      box-shadow: 0px 10px 10px #ccc;
}

.admin {
    color: red
}

.user {
    color: blue
}

.plusmin {
    width: 12px;
    margin-right: -10px;
    background-color: red
}

.version {
    color: whitesmoke;
    position: fixed;  // Fix the position relative to the viewport.
    bottom: 10px;        // Set it to the bottom of the viewport.
    right: 10px;         // Set it to the right of the viewport.
    padding: 10px;    // Optional: To ensure the text isn't sticking directly to the edge.
    z-index: 1000;    // Optional: If you want the text to be above other elements.
}


